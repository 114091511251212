// Depenencies
import React, { useReducer, createContext } from "react"

// Context API
const GlobalStateContext = createContext()
const GlobalDispatchContext = createContext()

// Initial global state of the entire app
const initialState = {
  modal: false,
  contactModal: false,
  downloadModal: false,
  popup: {
    state: false,
    goldLabel: true,
    blueLabel: false,
  },
}

// Reducer function to update Global state
const reducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MODAL": {
      return { ...state, modal: true }
    }

    case "CLOSE_MODAL": {
      return { ...state, modal: false }
    }

    case "OPEN_CONTACT_MODAL": {
      return { ...state, contactModal: true }
    }

    case "CLOSE_CONTACT_MODAL": {
      return { ...state, contactModal: false }
    }

    case "OPEN_DOWNLOAD_MODAL": {
      return { ...state, downloadModal: true }
    }

    case "CLOSE_DOWNLOAD_MODAL": {
      return { ...state, downloadModal: false }
    }

    case "OPEN_POPUP": {
      return {
        ...state,
        popup: {
          state: true,
          goldLabel: true,
          blueLabel: false,
        },
      }
    }

    case "OPEN_POPUP_BLUE_LABEL": {
      return {
        ...state,
        popup: {
          state: true,
          goldLabel: false,
          blueLabel: true,
        },
      }
    }

    case "CLOSE_POPUP": {
      return {
        ...state,
        popup: {
          state: false,
          goldLabel: true,
          blueLabel: false,
        },
      }
    }

    default: {
      throw new Error("Invalid Reducer action!")
    }
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
export { GlobalStateContext, GlobalDispatchContext }
