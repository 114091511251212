exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-application-procedure-gold-en-js": () => import("./../../../src/pages/application-procedure-gold.en.js" /* webpackChunkName: "component---src-pages-application-procedure-gold-en-js" */),
  "component---src-pages-application-procedure-silver-en-js": () => import("./../../../src/pages/application-procedure-silver.en.js" /* webpackChunkName: "component---src-pages-application-procedure-silver-en-js" */),
  "component---src-pages-application-procedure-standard-en-js": () => import("./../../../src/pages/application-procedure-standard.en.js" /* webpackChunkName: "component---src-pages-application-procedure-standard-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-data-protection-declaration-en-js": () => import("./../../../src/pages/data-protection-declaration.en.js" /* webpackChunkName: "component---src-pages-data-protection-declaration-en-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gold-label-beantragen-js": () => import("./../../../src/pages/gold-label-beantragen.js" /* webpackChunkName: "component---src-pages-gold-label-beantragen-js" */),
  "component---src-pages-governance-model-en-js": () => import("./../../../src/pages/governance-model.en.js" /* webpackChunkName: "component---src-pages-governance-model-en-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-imprint-en-js": () => import("./../../../src/pages/imprint.en.js" /* webpackChunkName: "component---src-pages-imprint-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-label-beantragen-en-js": () => import("./../../../src/pages/label-beantragen.en.js" /* webpackChunkName: "component---src-pages-label-beantragen-en-js" */),
  "component---src-pages-label-beantragen-js": () => import("./../../../src/pages/label-beantragen.js" /* webpackChunkName: "component---src-pages-label-beantragen-js" */),
  "component---src-pages-label-download-en-js": () => import("./../../../src/pages/label-download.en.js" /* webpackChunkName: "component---src-pages-label-download-en-js" */),
  "component---src-pages-label-download-js": () => import("./../../../src/pages/label-download.js" /* webpackChunkName: "component---src-pages-label-download-js" */),
  "component---src-pages-labeloverview-en-js": () => import("./../../../src/pages/labeloverview.en.js" /* webpackChunkName: "component---src-pages-labeloverview-en-js" */),
  "component---src-pages-labeluebersicht-js": () => import("./../../../src/pages/labeluebersicht.js" /* webpackChunkName: "component---src-pages-labeluebersicht-js" */),
  "component---src-pages-lieferantendatenbank-js": () => import("./../../../src/pages/lieferantendatenbank.js" /* webpackChunkName: "component---src-pages-lieferantendatenbank-js" */),
  "component---src-pages-lieferantenrisikomanagement-js": () => import("./../../../src/pages/lieferantenrisikomanagement.js" /* webpackChunkName: "component---src-pages-lieferantenrisikomanagement-js" */),
  "component---src-pages-list-question-b-en-js": () => import("./../../../src/pages/list-question-b.en.js" /* webpackChunkName: "component---src-pages-list-question-b-en-js" */),
  "component---src-pages-list-question-b-js": () => import("./../../../src/pages/list-question-b.js" /* webpackChunkName: "component---src-pages-list-question-b-js" */),
  "component---src-pages-list-question-en-js": () => import("./../../../src/pages/list-question.en.js" /* webpackChunkName: "component---src-pages-list-question-en-js" */),
  "component---src-pages-list-question-js": () => import("./../../../src/pages/list-question.js" /* webpackChunkName: "component---src-pages-list-question-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-nis-en-js": () => import("./../../../src/pages/nis.en.js" /* webpackChunkName: "component---src-pages-nis-en-js" */),
  "component---src-pages-nis-js": () => import("./../../../src/pages/nis.js" /* webpackChunkName: "component---src-pages-nis-js" */),
  "component---src-pages-partner-en-js": () => import("./../../../src/pages/partner.en.js" /* webpackChunkName: "component---src-pages-partner-en-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-payments-en-js": () => import("./../../../src/pages/payments.en.js" /* webpackChunkName: "component---src-pages-payments-en-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-preisuebersicht-js": () => import("./../../../src/pages/preisuebersicht.js" /* webpackChunkName: "component---src-pages-preisuebersicht-js" */),
  "component---src-pages-price-list-en-js": () => import("./../../../src/pages/price-list.en.js" /* webpackChunkName: "component---src-pages-price-list-en-js" */),
  "component---src-pages-silber-label-beantragen-js": () => import("./../../../src/pages/silber-label-beantragen.js" /* webpackChunkName: "component---src-pages-silber-label-beantragen-js" */),
  "component---src-pages-standard-label-beantragen-js": () => import("./../../../src/pages/standard-label-beantragen.js" /* webpackChunkName: "component---src-pages-standard-label-beantragen-js" */),
  "component---src-pages-steuerungsmodell-js": () => import("./../../../src/pages/steuerungsmodell.js" /* webpackChunkName: "component---src-pages-steuerungsmodell-js" */),
  "component---src-pages-supplier-database-en-js": () => import("./../../../src/pages/supplier-database.en.js" /* webpackChunkName: "component---src-pages-supplier-database-en-js" */),
  "component---src-pages-supplier-risk-management-en-js": () => import("./../../../src/pages/supplier-risk-management.en.js" /* webpackChunkName: "component---src-pages-supplier-risk-management-en-js" */)
}

