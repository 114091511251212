// Importing styles
import "./src/styles/custom.css";
import "./src/styles/grid/grid/_functions.css";
import "./src/styles/grid/grid/default-grid.css";
import "./src/styles/_request.css";
import "./src/styles/grid.css";
import "./src/styles/media.css";
import "./src/styles/slick-theme.css";
import "./src/styles/slick.css";
import "./src/styles/style.css";

// Managing Global state using Context API and useReduces hook
import React from "react";
import GlobalContextProvider from "./src/context/GlobalContextProvider";

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{ element }</GlobalContextProvider>
}